module controllers {
    export module logistics {
        interface ICostInvoiceScope extends ng.IScope {
        }

        export class costInvoiceCtrl {
            static $inject = ["$scope", '$rootScope', 'generalService', '$q', '$anchorScroll', 'bsLoadingOverlayService', '$state', '$timeout', 'entityService', 'commercialInvoiceService',
                "classificationValueService", "termsOfPaymentService", "currencyService", "supplierService", "isInbound", "warehouseService", "sundryItemService", "unitOfMeasureService",
                "commercialInvoiceSundryItemService", "$transitions", "uiGridConstants"];

            costInvoice: interfaces.logistics.ICommercialInvoice;

            breadCrumbDesc: string;

            public CIStatus: interfaces.master.IStatusData;

            public aLCWarehouseList: Array<interfaces.applicationcore.IDropdownModel>;

            public myHook: any;
            constructor(private $scope: ICostInvoiceScope,
                private $rootScope: interfaces.applicationcore.IRootScope,
                private generalService: interfaces.applicationcore.IGeneralService,
                private $q: ng.IQService,
                private $anchorScroll: ng.IAnchorScrollService,
                private bsLoadingOverlayService,
                private $state: ng.ui.IStateService,
                private $timeout: ng.ITimeoutService,
                private entityService: interfaces.applicationcore.IEntityService,
                private commercialInvoiceService: interfaces.logistics.ICommercialInvoiceService,
                private classificationValueService: interfaces.applicationcore.IClassificationValueService,
                private termsOfPaymentService: interfaces.master.ITermsOfPaymentService,
                private currencyService: interfaces.applicationcore.ICurrencyService,
                private supplierService: interfaces.master.ISupplierService,
                private isInbound: boolean,
                private warehouseService: interfaces.master.IWarehouseService,
                private sundryItemService: interfaces.master.ISundryItemService,
                private unitOfMeasureService: interfaces.master.IUnitOfMeasureService,
                private commercialInvoiceSundryItemService: interfaces.logistics.ICommercialInvoiceSundryItemService,
                private $transitions: ng.ui.core.ITransition,
                private uiGridConstants: uiGrid.IUiGridConstants) {

                    //if we are coming via the Manifest, it will tell us the isInbound flag based on Manifest route
                    if (this.$state.params['manIsInbound']) {                      
                        this.isInbound = this.$state.params['manIsInbound'];
                    }
                    
                this.myHook = $transitions.onSuccess({
                    to: '**.Update.CostInvoice',
                    from: '**.Update.CostInvoice.SundryItem'
                }, () => {
                    this.bsLoadingOverlayService.wrap({
                        referenceId: 'cost.invoice.update'
                    }, () => {
                        return this.loadCostInvoice();
                    });
                });

                $scope.$on('$destroy', () => {
                    $transitions.onStart({}, this.myHook)
                });

                bsLoadingOverlayService.wrap({
                    referenceId: 'cost.invoice.update'
                }, () => {
                    return this.loadCostInvoice();
                });
            }

            loadCostInvoice() {
                if (this.$state.params['conId']) {
                    this.$state.params['manId'] = undefined;
                }

                return this.commercialInvoiceService.get(this.$state.params['comId'], this.$state.params['conId'], this.$state.params['manId'], true).get((result: interfaces.logistics.ICommercialInvoice) => {

                    if(this.costInvoice) {
                        result.ControlTotal = this.costInvoice.ControlTotal;
                    }

                    this.costInvoice = result;
                    this.gvwSundryItems.data = this.costInvoice.CommercialInvoiceSundryItemList;

                    this.setBreadCrumbDesc();
                    this.setStatus();

                    this.$timeout(() => {
                        if (this.apiSundryItem) {
                            this.apiSundryItem.core.notifyDataChange(this.uiGridConstants.dataChange.ALL);
                        }
                    });
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                }).$promise;
            }

            setBreadCrumbDesc() {
                this.breadCrumbDesc = 'Cost Invoice: ' + this.costInvoice.Number;
            }

            invoiceNumberChanged() {
                this.setBreadCrumbDesc();
            }

            setStatus() {

                this.CIStatus = {
                    StatusName: this.costInvoice.CostInvoiceStatusCode + ' - ' + this.costInvoice.CostInvoiceStatusDescription,
                    Id: this.costInvoice.Id,
                    EntityId: this.costInvoice.OwnerEntity.Id,
                    CurrentStatusId: this.costInvoice.CostInvoiceStatusId,
                    CurrentStatusComments: this.costInvoice.CostInvoiceStatusComments,
                    CurrentStatusStampDate: this.costInvoice.CostInvoiceStatusLastUpdateStampDate,
                    CurrentStatusStampFullName: this.costInvoice.CostInvoiceStatusLastUpdateStampFullName,
                    CurrentStatusType: Enum.EnumStatusType.CostInvoice,
                    EnumStatusType: Enum.EnumStatusType.CostInvoice,
                    EnumStatusAuthorisationType: Enum.EnumStatusAuthorisationType.CostInvoice,
                    IsEditable: true
                };
            }

            loadEntities(searchText: string) {
                if (this.costInvoice) {
                    return this.entityService.getDropdownList("", this.costInvoice.OwnerEntity.Id).query().$promise;
                } else {
                    var defer = this.$q.defer();
                    defer.resolve([]);
                    return defer.promise
                }
            }

            entityChanged(model: interfaces.applicationcore.IDropdownModel) {
                this.$timeout(() => {
                    this.costInvoice.Supplier = undefined;
                    this.costInvoice.TermsOfPayment = undefined;

                    this.costInvoice.ALCWarehouse = undefined;
                    this.aLCWarehouseList = [];
                });
            }

            loadCreditTypes(searchText: string) {
                return this.classificationValueService.GetClassificationValueDropdownByClassification().query({
                    classificationId: Enum.ClassificationEnum.InvoiceCreditType
                }, () => {
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                }).$promise;
            }

            loadTermsOfPayments(searchText: string) {
                var defered = this.$q.defer();

                if (this.costInvoice) {
                    this.termsOfPaymentService.getTermsOfPaymentDropdown(this.costInvoice.OwnerEntity.Id, searchText).query((termsOfPaymentList: Array<interfaces.applicationcore.IDropdownModel>) => {
                        defered.resolve(termsOfPaymentList);
                    }, (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                        defered.resolve([]);
                    });
                } else {
                    defered.resolve([]);
                }

                return defered.promise;
            }

            loadCurrencies() {

                return this.currencyService.getCurrencyDropdownList().query((currencyList: Array<interfaces.applicationcore.IDropdownModel>) => {
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                }).$promise;
            }

            loadALCApportionment(searchText: string) {
                return this.classificationValueService.GetClassificationValueDropdownByClassification().query({
                    classificationId: Enum.ClassificationEnum.CustomsApportionment
                }, () => {
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                }).$promise;
            }

            loadWarehouses() {
                if (this.costInvoice) {
                    return this.warehouseService.getDropdownList().query({
                        countryId: this.costInvoice.LoadingCountryId,
                        ownerEntityId: this.costInvoice.OwnerEntity.Id
                    }, () => {
                    }, (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    }).$promise;
                } else {
                    var defer = this.$q.defer();
                    defer.resolve([]);
                    return defer.promise;
                }
            }

            loadSuppliers(searchText: string) {
                if (this.costInvoice) {
                    return this.supplierService.getSupplierDropdown(this.costInvoice.OwnerEntity.Id, searchText).query(() => { }, (errorResponse) => {

                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    }).$promise;
                } else {
                    var defer = this.$q.defer();
                    defer.resolve([]);
                    return defer.promise;
                }
            }

            supplierChanged(model) {
                if (model) {
                    this.bsLoadingOverlayService.wrap({
                        referenceId: 'cost.invoice.update'
                    }, () => {
                        return this.commercialInvoiceService.getSupplierDefault(this.costInvoice.OwnerEntity.Id, model.Id, this.isInbound, true).get((result) => {

                            this.costInvoice.IsZeroRated = result.IsZeroRated;

                            if (result.Currency) {
                                this.costInvoice.Currency = result.Currency;
                            }

                            if (result.TermsOfPayment) {
                                this.costInvoice.TermsOfPayment = result.TermsOfPayment;
                            }

                        }, (errorResponse) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                        }).$promise;
                    });
                }

            }

            loadUoMList() {
                if (this.costInvoice) {
                    return this.unitOfMeasureService.getUoMDropdown().query({ ownerEntityId: this.costInvoice.OwnerEntity.Id }, () => { }, (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    }).$promise;
                } else {
                    var defered = this.$q.defer();
                    defered.resolve([]);
                    return defered.promise;
                }
            }

            loadDIs() {
                if (this.costInvoice && this.costInvoice.ConsignmentId) {
                    return this.commercialInvoiceService.getDILookupSearchByConsignmentId(this.costInvoice.ConsignmentId).query(() => { }, (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    }).$promise;
                } else {
                    var defered = this.$q.defer();
                    defered.resolve([]);
                    return defered.promise;
                }
            }

            apportionmentChanged() {
                if (this.apiSundryItem) {
                    this.apiSundryItem.rowEdit.setRowsDirty(this.costInvoice.CommercialInvoiceSundryItemList);
                }
            }

            updateStatus(newStatusId: number, comments: string) {
                var paramsObject: interfaces.master.IStatusChange = {
                    referenceId: this.costInvoice.Id,
                    newStatusId: newStatusId,
                    comments: comments
                };
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'cost.invoice.update'
                },
                    () => {
                        return this.commercialInvoiceService.changeStatus(this.costInvoice.Id, newStatusId, this.costInvoice.Version, comments, this.costInvoice.CostInvoiceStatusId).save({}, paramsObject, (data: interfaces.applicationcore.IMessageHandler) => {
                            this.generalService.displayMessageHandler(data);
                            if (!data.HasErrorMessage) {
                                this.$timeout(() => {
                                    this.bsLoadingOverlayService.wrap({
                                        referenceId: 'cost.invoice.update'
                                    }, () => {
                                        return this.loadCostInvoice();
                                    });
                                });
                            }
                        }, (failureData) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                        }).$promise;
                    });
            };

            calculatePaymentDate_click() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'cost.invoice.update'
                },
                    () => {
                        return this.saveCostInvoice().then((result) => {
                            if (result.HasErrorMessage) {
                                this.generalService.displayMessageHandler(result);
                            } else {
                                this.$timeout(() => {
                                    this.calculatePaymentDate();
                                });
                            }
                        });
                    });
            }

            calculatePaymentDate() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'cost.invoice.update'
                },
                    () => {
                        return this.commercialInvoiceService.calculatePaymentDueDate(this.isInbound, this.costInvoice.Id).save((data: interfaces.applicationcore.IMessageHandler) => {
                            this.generalService.displayMessageHandler(data);

                            if (!data.HasErrorMessage) {
                                this.$timeout(() => {
                                    this.bsLoadingOverlayService.wrap({
                                        referenceId: 'cost.invoice.update'
                                    }, () => {
                                        return this.loadCostInvoice();
                                    });
                                });
                            }

                        }, (failureData) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                        }).$promise;
                    });
            }

            apiSundryItem: uiGrid.IGridApi;
            gvwSundryItems: uiGrid.IGridOptions = {
                data: [],
                enableFiltering: true,
                useExternalFiltering: false,
                enableCellEdit: false,
                useExternalSorting: false,
                multiSelect: false,
                enableColumnResizing: true,
                enableFullRowSelection: true,
                useExternalPagination: false,
                enableRowHeaderSelection: false,
                enableHorizontalScrollbar: 2,
                rowEditWaitInterval: -1,
                showGridFooter: true,
                cellEditableCondition: () => { return this.costInvoice.LockdownType < 2 },
                onRegisterApi: (gridApi) => {
                    this.apiSundryItem = gridApi;

                    this.apiSundryItem.cellNav.on.navigate(this.$scope, (newCol) => {
                        this.$timeout(() => {
                            this.apiSundryItem.selection.selectRow(newCol.row.entity);
                        });
                    });
                },
                columnDefs: [{
                    name: "EDIT",
                    field: "Id",
                    displayName: "",
                    enableFiltering: false,
                    width: 32,
                    enableCellEdit: false,
                    enableCellEditOnFocus: false,
                    cellTemplate: `
                    <div class="GridButton"> 
                        <button type="button" class="btn btn-default btn-sm" ng-click="grid.appScope.costInvoiceCtrl.editSundryItem(MODEL_COL_FIELD)" ng-if="MODEL_COL_FIELD">
                            <span class="fa fa-pencil"></span>
                        </button>
                    </div>`,
                    headerCellTemplate: `
                    <div class="GridButton"> 
                        <button type="button" class="btn btn-default btn-sm"  ng-click="grid.appScope.costInvoiceCtrl.addInlineSundryItem()" ng-if="grid.appScope.costInvoiceCtrl.costInvoice.LockdownType < 2">
                            <span class="fa fa-plus"></span>
                        </button>
                    </div>`
                }, {
                    name: "SUNITM",
                    displayName: "*Sundry Item",
                    field: "SundryItem",
                    enableFiltering: true,
                    enableCellEdit: true,
                    enableCellEditOnFocus: true,
                    width: 200,
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD.Display}}</div>`,
                    editableCellTemplate: `<form name="inputForm">
                                                <gts-dropdown ui-select-wrap name="code-{{MODEL_COL_FIELD.$$hashKey}}" append-to-body="true" ng-model="MODEL_COL_FIELD" form="inputForm" load-data="grid.appScope.costInvoiceCtrl.loadSundryItems(searchText)" ng-change="grid.appScope.costInvoiceCtrl.sundryItemChanged(model, row.entity)" required="true" pagination-search="true" refresh-delay="1000"></gts-dropdown>
                                            </form>`,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                            </div>`,
                    filter: {
                        condition: (searchTerm, cellValue) => {
                            return (cellValue.Display.toLocaleLowerCase().indexOf(searchTerm.toLocaleLowerCase()) >= 0 || searchTerm.length === 0);
                        }
                    },
                    sortingAlgorithm: (a, b, rowA, rowB, direction) => {
                        var nulls = this.apiSundryItem.core.sortHandleNulls(a, b);
                        if (nulls !== null) {
                            return nulls;
                        } else {
                            if (a === b) {
                                return 0;
                            }

                            if (direction === "desc") {
                                if (a.Display < b.Display) return -1;
                                if (a.Display > b.Display) return 1;
                            } else if (direction === "asc") {
                                if (a.Display > b.Display) return 1;
                                if (a.Display < b.Display) return -1;
                            }

                            return 0;
                        }
                    }
                }, {
                    name: "SUNDESC",
                    displayName: "*Sundry Item Description",
                    field: "SundryItemDescription",
                    width: 300,
                    enableCellEdit: true,
                    enableCellEditOnFocus: true,
                    editableCellTemplate: `
                                <form name="inputForm">
                                    <p class="input-group-sm">
                                        <input type="text" name="{{MODEL_COL_FIELD.$$hashKey}}-sunDesc" autocomplete="off" ng-model="MODEL_COL_FIELD" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor />
                                    </p>
                                </form>`,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                            </div>`
                }, {
                    name: "COSTEL",
                    displayName: "Cost Model Element",
                    field: "CostModelElement",
                    width: 175,
                    enableCellEdit: false,
                    enableCellEditOnFocus: false,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                            </div>`
                }, {
                    name: "QTY",
                    displayName: "*Quantity",
                    field: "Quantity",
                    width: 75,
                    enableCellEdit: true,
                    enableCellEditOnFocus: true,
                    cellFilter: "number: 2",
                    editableCellTemplate: `
                                <form name="inputForm">
                                    <p class="input-group-sm">
                                        <input type="number" name="{{MODEL_COL_FIELD.$$hashKey}}-qty" autocomplete="off" ng-model="MODEL_COL_FIELD" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor step="0.01" ng-change="grid.appScope.costInvoiceCtrl.quantityChanged(row.entity)" />
                                    </p>
                                </form>`,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                            </div>`
                }, {
                    name: "SELLPRICE",
                    displayName: "*Selling Price",
                    field: "SellingPrice",
                    width: 150,
                    enableCellEdit: true,
                    enableCellEditOnFocus: true,
                    cellFilter: "number: 2",
                    editableCellTemplate: `
                                <form name="inputForm">
                                    <p class="input-group-sm">
                                        <input type="number" name="{{MODEL_COL_FIELD.$$hashKey}}-sellprice" autocomplete="off" ng-model="MODEL_COL_FIELD" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor step="0.01" ng-change="grid.appScope.costInvoiceCtrl.sellingPriceChanged(row.entity)" />
                                    </p>
                                </form>`,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="number" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }" step="0.01"/></div>
                            </div>`
                }, {
                    name: "SELLVALUE",
                    displayName: "*Selling Value",
                    field: "SellingValue",
                    width: 150,
                    enableCellEdit: false,
                    enableCellEditOnFocus: false,
                    cellFilter: "number: 2",
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                <input type="number" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }" step="0.01"/></div>
                            </div>`
                }, {
                    name: "VATVALUE",
                    displayName: "*VAT Value",
                    field: "VATValue",
                    width: 150,
                    enableCellEdit: false,
                    enableCellEditOnFocus: false,
                    cellFilter: "number: 2",
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                <input type="number" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }" step="0.01"/></div>
                            </div>`
                }, {
                    name: "CURCODE",
                    displayName: "Currency Code",
                    field: "CurrencyCode",
                    width: 150,
                    enableCellEdit: false,
                    enableCellEditOnFocus: false,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                            </div>`
                    }, {
                        name: "ISDUT",
                        displayName: "Is Dutiable",
                        field: "IsDutiable",
                        width: 175,
                        enableCellEdit: false,
                        enableCellEditOnFocus: false,
                        cellTemplate: '<input type="checkbox" ng-model="MODEL_COL_FIELD" disabled>',
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="checkbox" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                            </div>`
                    }, {
                        name: "INCLPRICE",
                        displayName: "Is Included In Price",
                        field: "IsIncludingInPrice",
                        width: 175,
                        enableCellEdit: false,
                        enableCellEditOnFocus: false,
                        cellTemplate: '<input type="checkbox" ng-model="MODEL_COL_FIELD" disabled>',
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="checkbox" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                            </div>`
                    }]
            };

            loadSundryItems(searchText: string) {
                if (this.costInvoice) {
                    return this.sundryItemService.getDropdownList(this.costInvoice.OwnerEntity.Id,this.isInbound, searchText).query(() => {

                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    }).$promise;
                } else {
                    var defer = this.$q.defer();
                    defer.resolve([]);
                    return defer.promise;
                }
            }

            sundryItemChanged(model: interfaces.applicationcore.IDropdownModel, entity: interfaces.logistics.ICommercialInvoiceSundryItemDisplay) {
                if (model) {
                    this.bsLoadingOverlayService.wrap({
                        referenceId: 'cost.invoice.update'
                    },
                        () => {
                            return this.sundryItemService.getDefault().get({ sunId: model.Id, countryId: this.costInvoice.SupplierCountryId }, (result: interfaces.master.ISundryItemDefault) => {
                                entity.IsDutiable = result.Dutiable;
                                entity.IsIncludingInPrice = result.IsIncludedInPrice;
                                entity.VATRate = result.VatRateRate;

                                if (result.CostModelElement)
                                    entity.CostModelElement = result.CostModelElement.Display;

                                entity.SundryItemDescription = result.Description;

                                entity.Quantity = 1;
                                entity.SellingPrice = 0;
                                entity.VATValue = 0;
                                entity.SellingValue = 0;

                            }, (errorResponse) => {
                                this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                            }).$promise;
                        });
                }
            }

            quantityChanged(entity: interfaces.logistics.ICommercialInvoiceSundryItemDisplay) {
                this.calculateVat(entity);
            }

            sellingPriceChanged(entity: interfaces.logistics.ICommercialInvoiceSundryItemDisplay) {
                this.calculateVat(entity);
            }

            calculateVat(entity: interfaces.logistics.ICommercialInvoiceSundryItemDisplay) {
                entity.SellingValue = Number((Math.round(entity.Quantity * entity.SellingPrice).toFixed(2)));
                entity.VATValue = Number((Math.round(entity.SellingValue * entity.VATRate) / 100));
            }

            addInlineSundryItem() {
                var sunItem = {
                    CurrencyCode: this.costInvoice.Currency.Code,
                    Quantity: 1
                };

                (<Array<any>>(this.gvwSundryItems.data)).splice(0, 0, sunItem);
            }

            editSundryItem(cisId: number) {
                if (this.$state.current.name.indexOf("auth.Manifests.Update.ConsignmentExport.CostInvoice") > -1) {
                    this.$state.go("auth.Manifests.Update.ConsignmentExport.CostInvoice.SundryItem", { cisId: cisId });
                }else if (this.$state.current.name.indexOf("auth.Manifests.Update.ConsignmentImport.CostInvoice") > -1) {
                    this.$state.go("auth.Manifests.Update.ConsignmentImport.CostInvoice.SundryItem", { cisId: cisId });
                } else if (this.costInvoice.ConsignmentId) {
                    if (this.isInbound) {
                        this.$state.go("auth.ConsignmentImports.Update.CostInvoice.SundryItem", { cisId: cisId });
                    } else {
                        this.$state.go("auth.ConsignmentExports.Update.CostInvoice.SundryItem", { cisId: cisId });
                    }
                } else if (this.costInvoice.ManifestId) {
                    this.$state.go("auth.Manifests.Update.CostInvoice.SundryItem", { cisId: cisId });
                }
            }

            removeSundryItem() {
                var selectedRow = <interfaces.consignment.IConsignmentDetailDisplay>this.apiSundryItem.selection.getSelectedRows()[0];
                if (selectedRow && selectedRow.Id > 0) {
                    this.bsLoadingOverlayService.wrap({
                        referenceId: 'cost.invoice.update'
                    }, () => {
                        return this.commercialInvoiceSundryItemService.delete(selectedRow.Id).delete((result) => {
                            this.generalService.displayMessageHandler(result);
                            if (!result.HasErrorMessage) {
                                this.$timeout(() => {
                                    this.bsLoadingOverlayService.wrap({
                                        referenceId: 'cost.invoice.update'
                                    }, () => {
                                        return this.loadCostInvoice();
                                    });
                                });
                            }

                        }, (errorResponse) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                        }).$promise;
                    });
                } else if (selectedRow) {
                    _.remove(this.gvwSundryItems.data, (o) => {
                        return o === selectedRow;
                    });
                }
            }

            saveCostInvoice() {
                if (this.apiSundryItem) {
                    this.costInvoice.CommercialInvoiceSundryItemList = _.map(this.apiSundryItem.rowEdit.getDirtyRows(), (o) => {
                        return o.entity;
                    });
                } else {
                    this.costInvoice.CommercialInvoiceSundryItemList = [];
                }

                return this.commercialInvoiceService.save().save({}, this.costInvoice, (result: interfaces.applicationcore.IMessageHandler) => {
                    this.generalService.displayMessageHandler(result);

                    if (!result.HasErrorMessage) {
                        if (this.apiSundryItem) {
                            this.apiSundryItem.rowEdit.setRowsClean(this.costInvoice.CommercialInvoiceSundryItemList);
                        }
                    }

                    this.$anchorScroll("topAnchor");
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    this.$anchorScroll("topAnchor");
                }).$promise;
            }

            Save() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'cost.invoice.update'
                }, () => {
                    return this.saveCostInvoice().then((result) => {

                        if (!result.HasErrorMessage && !this.$state.params["comId"]) {
                            this.$state.params["comId"] = Number(result.ID);
                            this.$state.go(this.$state.current.name, this.$state.params);
                        } else if (!result.HasErrorMessage) {
                            this.$timeout(() => {
                                this.bsLoadingOverlayService.wrap({
                                    referenceId: 'cost.invoice.update'
                                }, () => {
                                    return this.loadCostInvoice();
                                });
                            });

                        }


                    });
                });
            }
            
            SaveClose() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'cost.invoice.update'
                }, () => {
                    return this.saveCostInvoice().then((result) => {

                        if (!result.HasErrorMessage) {
                            this.$state.go("^");
                        }
                    });
                });
            }

            Close() {
                this.$state.go("^");
            }
        }

        angular.module("app").controller("costInvoiceCtrl", controllers.logistics.costInvoiceCtrl);
    }
}